@import "~react-image-gallery/styles/scss/image-gallery.scss";

.image-gallery {
    width: auto;
    object-fit: cover;
}

.image-gallery-slide img {
    height: 50vh;
    width: auto;
    max-height: 80vh;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    height: 100vh;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 80px;
    width: 100px;
}